/*
 * 업무구분: 고객
 * 화 면 명: MSPCM124M
 * 화면설명: 이벤트고객
 * 작 성 일: 2022.06.10
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container title="이벤트고객" :show-title="true" type="subpage" action-type="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM116P()" @on-scroll-bottom="fn_LoadingData()" :topButton="true">
    <!-- 전체체크 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="checkDisabled || isEmptyView" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">{{ checkCount > 0 ? checkCount + '명 선택' : '총 ' + cmICMemrdCustVO.length + '명' }}</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <!-- 조회결과 리스트 -->
        <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-list listStyle">
          <mo-list :list-data="cmICMemrdCustVO">
            <template #list-item="{ index, item }">
              <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <!-- 체크박스 -->
                <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount" :disabled="checkDisabled" :ref="`chk${index}`"/>
                <div class="list-item__contents">
                  <div class="list-item__contents__title ns-area-left no-title__ellip">
                    <!-- 고객명 -->
                    <span class="name" @click.stop="fn_OpenCustInfoBs(item)">{{ item.custNm }}</span>
                    <!-- 생년월일 -->
                    <span class="day txtSkip">{{ $commonUtil.dateDashFormat(item.rrnFrnoNo) }}</span>
                  </div>
                  <div class="ns-area-right">
                    <mo-badge class="badge-sample-badge" text="오늘" shape="status" v-if="item.evtYmd === $bizUtil.getDateToString(new Date(), '-')">오늘</mo-badge>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 이벤트명 -->
                    <span>{{ item.evtTypNm }}</span><em>|</em>
                    <!-- 이벤트날짜 -->
                    <span>{{ item.evtYmd }}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <!-- NoData 영역  -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>

        <!-- Toast -->
        <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
        <div v-if="cmICMemrdCustVO.length > 0" class="ns-height40"></div>

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 전체고객 목록 검색
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM124M',
  // 현재 화면 ID
  screenId: 'MSPCM124M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPCM116P,
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 화면명
      title: '이벤트고객',
      
      pCustPtclrMatrCntnt: String,
      pChnlCustId: String,
      pCnsltNo: String,

      isMngr: false, // 지점장 여부
      pOrgData124M: {}, // 최근 조직 조회 데이터
      toastMsg: '', // 문자발송 toast 문구

      // 팝업 연동
      popup116: {}, // MSPCM116P 전체고객 목록 검색

      // 체크 변수
      checkDisabled: false, // 체크박스 비활성화 여부
      isOneOrMoreCheck: false, // 전체선택
      selectItems: [], // 선택된 고객 list
      custListObj: [], // 문자수신 고객 list
      isCustCardExist: true, // 채널아이디 여부

      // 조회 변수
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      lv_cnsltNo: '', // 컨설턴트 번호
      searchKeyword: '', // 고객명
      cmICMemrdCustVO: [], // 조회용 Service IF VO
      lv_inputParamS: {}, // 조회 Obj
      iCCdCustMktPsbInfoVO: { // 고객카드의 동의(BP정보)
        mktCnvsMthTelYn: ''
      },
      cmCustCardInfoSVO: { // 고객 상세정보 인적사항
        chnlCustId: ' ',
        custId: ' ',
        telno: ''
      },
      pageRowCnt: '20', // page count
      stndKeyList: [], // 더보기 변수
      stndKeyVal: '', // 더보기 변수

      // 날짜 변수(---팝업에 사용 예정)
      nowDate: moment(new Date()).format('YYYY-MM-DD'), // 오늘
      startDate: this.$bizUtil.getDateToString(new Date(), '-'), // 시작일 : 오늘
      endDate: this.$bizUtil.getLastDayOfNowMonth('String'), // 종료일 : 당월 말일
      
      stEvtTypCd: '', // 고객구분 대분류
      stEvtCdKey: '', // 고객구분 중분류

      isSearched: false // 조회이력 저장 변수

    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    checkCount () {
      return this.cmICMemrdCustVO.filter(item => item.checked).length
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.cmICMemrdCustVO.length && this.cmICMemrdCustVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 이벤트 고객 조회
    this.fn_Submit()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM124M')
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData124M = {} // 조직 조회 데이터
          this.searchKeyword = '' // 고객명
          this.stEvtTypCd = '' // 고객구분
          this.stEvtCdKey = ''
          this.startDate = this.$bizUtil.getDateToString(new Date(), '-') // 조회시작일자
          this.endDate = this.$bizUtil.getLastDayOfNowMonth('String') // 조회종료일자
  
          this.isSearched = false // 검색이력 초기화
  
          // 재조회
          this.fn_Submit()
        } else {
          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_LoadingData
     * 설명       : 인피니트 로딩데이터
     ******************************************************************************/
    fn_LoadingData () {
      if (!this.isLoading) {
        // 더보기 시 전체체크 되어있으면 전체체크 해제
        if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
          this.isOneOrMoreCheck = false
        }

        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')

        // 이벤트고객 목록 조회
        this.fn_SelListMemrdCust()
      }
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [] // 초기로드 or 조회버튼시
      this.selectItems.splice(0, this.selectItems.length)
      this.cmICMemrdCustVO = [] // 인피니트 바인딩 데이터
      this.cmICMemrdCustVO.splice(0, this.cmICMemrdCustVO.length)
      this.isEmptyView = false // 데이터가 없습니다 visible 여부

      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData124M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData124M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      // post 호출 전 Progress 타입 설정.
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.fn_SelListMemrdCust() // 이벤트고객 목록 조회
    },

    /******************************************************************************
     * Function명 : fn_SelListMemrdCust
     * 설명       : 서비스 호출(이벤트고객 목록 조회)
     ******************************************************************************/
    fn_SelListMemrdCust () {
      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM33S7'// 이벤트고객 목록 조회
      const trnstId = 'txTSSCM47S8'// 이벤트고객 목록 조회
      const auth = 'S'
      const lv_Vm = this

      let confirm = this.getStore('confirm')

      this.lv_inputParamS = {}
      // 지점장 조회시 구분
      if (this.pOrgData124M.cnslt !== undefined && this.isMngr === true) {
        this.lv_inputParamS.cnsltNo  = this.pOrgData124M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        this.lv_inputParamS.cnsltNo  = this.lv_cnsltNo // 컨설턴트 번호
      }
      this.lv_inputParamS.custNm = this.searchKeyword // 고객명
      this.lv_inputParamS.evtTypCd = this.stEvtTypCd // 고객구분 대분류
      this.lv_inputParamS.evtCd = this.stEvtCdKey    // 고객구분 중분류
      this.lv_inputParamS.inqrStrYmd = this.startDate.replace(/-/gi, '').substring(0, 8) // 조회시작일자
      this.lv_inputParamS.inqrEndYmd = this.endDate.replace(/-/gi, '').substring(0, 8) // 조회종료일자

      let pParams = {}
      pParams = this.lv_inputParamS

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.iCMemrdCustVO
            lv_Vm.exceptCnt = response.body.respMsgCntnt
            if (responseList !== null) {
              for (let i = 0; i < responseList.length; i++) {
                responseList[i].disabled = lv_Vm.disabled
                responseList[i].checked = false
                responseList[i].evtYmd = lv_Vm.$commonUtil.dateDashFormat(responseList[i].evtYmd)
                responseList[i].rrnFrnoNo = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.$bizUtil.getRrnToBirthDay(responseList[i].rrnFrnoNo))
                responseList[i].rntTuchYmd = lv_Vm.$commonUtil.dateDashFormat(responseList[i].rntTuchYmd)
              }

              if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.cmICMemrdCustVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.cmICMemrdCustVO = responseList
              }

              if (responseList.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }

              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.cmICMemrdCustVO.length

              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList

              // 다음 데이터 존재시 more, 없으면 end
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
                lv_Vm.stndKeyVal = 'end'
              } else {
                lv_Vm.stndKeyVal = 'more'
              }
            } else {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      this.$nextTick(() => {
        if (this.cmICMemrdCustVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in this.cmICMemrdCustVO) {
            if (this.isOneOrMoreCheck) {
              this.cmICMemrdCustVO[n].checked = true
            } else {
              this.cmICMemrdCustVO[n].checked = false
            }
          }
          this.fn_CheckSelectedItemCount()
        }
      })
    },
    
    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount () {
      this.selectItems = [] // 초기화
      this.selectItems = this.cmICMemrdCustVO.filter(item => item.checked)

      // 고객 체크 시 BottomSheet
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
    },

    /*********************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     *********************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: 'MSPCM124M',
          pOrgData: this.pOrgData124M, // 최근 조직 조회 데이터
          pSearchKeyword: this.searchKeyword, // 고객명
          pStEvtTypCd: this.stEvtTypCd, // 고객구분
          pStEvtCdKey: this.stEvtCdKey,
          pStartDate: this.startDate, // 조회시작일자
          pEndDate: this.endDate, // 조회종료일자
          pIsMngr: this.isMngr, // 지점장 여부
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup116)

            this.searchKeyword = pData.rtnData.searchKeyword // 고객명
            this.stEvtTypCd = pData.rtnData.stEvtTypCd // 고객구분
            this.stEvtCdKey = pData.rtnData.stEvtCdKey // 고객구분
            this.startDate = pData.rtnData.startDate // 조회시작일자
            this.endDate = pData.rtnData.endDate // 조회종료일자

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData124M = pData.rtnData.pOrgData

            // 조회이력 저장
            this.isSearched = true

            // 이벤트 고객 조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}
      
      if(this.pOrgData124M.cnslt === undefined || this.pOrgData124M.cnslt === null || this.pOrgData124M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData124M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPCM124M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData124M = {} // 조직 조회 데이터
                this.searchKeyword = '' // 고객명
                this.stEvtTypCd = '' // 고객구분
                this.stEvtCdKey = ''
                this.startDate = this.$bizUtil.getDateToString(new Date(), '-') // 조회시작일자
                this.endDate = this.$bizUtil.getLastDayOfNowMonth('String') // 조회종료일자

                this.isSearched = false // 검색이력 초기화

                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
  }
}
</script>
